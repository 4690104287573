// import cookies from 'browser/browser-v1.0.0';
import 'modal/modal-v2.3.0';
import 'browser/browser-v1.0.0';

$(document).ready(function () {
	$('.nav-toggle').on('click', function () {
		$('.nav-toggle').toggleClass('open');
		$('nav').toggleClass('open');
		$('body').toggleClass('disable-scroll');
	});
});